import React from 'react';

import './Header.css'

const columnTitles = ['APARELHO', 'MODELO', 'POTÊNCIA', 'QUANTIDADE', 'TEMPO'];

export default function Header() {
  return (
    <section className='header'>
      {columnTitles.map((title, idx) =>
        <div className='column-title' key={idx}>
          {title}
        </div>
      )}
    </section>
  );
}