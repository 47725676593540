import React from 'react';
import { string, arrayOf, oneOfType, shape, func } from 'prop-types';

export default function Select({ name, value, data = [], onChange }) {

  return (
    <select
      onChange={onChange}
      className='input'
      name={name}
      value={value}
    >
      <option value='' disabled hidden>
        {`Selecione o ${name}`}
      </option>

      {data.map((item, idx) =>
        <option key={idx} value={item.modelo || item}>
          {item.modelo || item}
        </option>
      )}
    </select>
  );
}

Select.propTypes = {
  name: string,
  value: string,
  data: arrayOf(oneOfType([
    string,
    shape({
      modelo: string,
      potencia: string
    })
  ])),  
  onChange: func
}