import React, { useState } from 'react';

import Title from './components/Title'
import Table, { inputs } from './components/Table';
import Results from './components/Results';


function App() {
  const [calcularClicked, setCalcularClicked] = useState(false);
  const [lines, setLines] = useState([{...inputs}]);
  /*
  lines = [
    // Linha 1
    { aparelho: 'Geladeira', setor: 'Refrigerador', modelo: 'Eletrolux', potencia: '1500', quantidade: '1', tempo: '24' },
    // Linha 2
    { aparelho: 'Televisão', setor: 'Lazer', modelo: 'LG', potencia: '2000', quantidade: '2', tempo: '10' },
    // Linha 3
    { aparelho: '', setor: '', modelo: '', potencia: '', quantidade: '', tempo: '' },
    ...
  ]
  */

  return (
    <>
      <Title/>
      <Table lines={lines} setLines={setLines} setCalcularClicked={setCalcularClicked}/>
      {calcularClicked && (
        <Results lines={lines} calcularClicked={calcularClicked} />
      )}
    </>
  );
}
export default App;
