import React, { useEffect, useState } from 'react';
import Chart from "react-google-charts";
import powerCalculation from '../utils/powerCalculation';

import './Loader.css'

export default function PieChart({ calcularClicked, lines }) {
	const [data, setData] = useState();

	function updateData() {
		let header = ['Setor', 'Potencia']
		let newAparelhos = []

		lines.forEach(line => {
			let idx = newAparelhos.findIndex(setor => setor[0] === line.setor)

			if (idx !== -1) {
				newAparelhos[idx][1] += parseFloat(powerCalculation(line))
			} else {
				newAparelhos.push([line.setor, parseFloat(powerCalculation(line))])
			}
		})

		let newData = [header, ...newAparelhos]

		setData(newData)
	}

	useEffect(() => {
		updateData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [calcularClicked])

	return (
		<Chart
			width={'100%'}
			height={'400px'}
			chartType='PieChart'
			loader={<div className="loader" > </div>}
			data={data}
			options={
				{
					fontSize: 16,
					pieHole: 0.4,
					colors: ["ff9500", "ffac13", "ffc90a", "ffe817", "ffee50"],
					chartArea: {
						left: 30,
						top: 30,
						bottom: 60,
						right: 30,
					},
					legend: {
						alignment: 'center',
						position: 'bottom',
					}
				}
			}
		/>
	);
};