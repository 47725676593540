import React, { useEffect, useState } from 'react';
import Chart from "react-google-charts";
import powerCalculation from '../utils/powerCalculation';

import './Loader.css'

// renderAnnotation é chamada quando passa o mouse em cima da barra
function renderAnnotation({ modelo, potencia }) {
    return (`
    <div style='padding: 10px; font-size: 16px;'>
      <strong>${modelo}</strong></br>
      Potência: <strong>${potencia} W</strong></br>
    </div>
  `)
}

export default function BarChart({ calcularClicked, lines }) {
    const [data, setData] = useState();

    function updateData() {
        /*
          Estado data precisa estar nesse formato de acordo com a documentação
          https://react-google-charts.com/bar-chart

        newData = [
          ['Aparelho', 'Potência', {'type': 'string', 'role': 'tooltip', 'p': {'html': true}}, 'Potência', {'type': 'string', 'role': 'tooltip', 'p': {'html': true}}, 'Potência', {'type': 'string', 'role': 'tooltip', 'p': {'html': true}}],
          ['Geladeira', 1000, 'LG', 400, 'Brastem', 200, 'Eletrolux'],
          ['Televisão', 1170, 'LG', 460, 'Samsung', 250, 'Apple'],
          ['Microondas', 660, 'Brastem', 1120, 'Eletrolux', null, '']
        ]
        */

        /*
          newAparelhos = [
          ['Geladeira', 1000, 'LG', 400, 'Brastem', 200, 'Eletrolux'],
          ['Televisão', 1170, 'LG', 460, 'Samsung', 250, 'Apple'],
          ['Microondas', 660, 'Brastem', 1120, 'Eletrolux', null, '']
          ]
        */
        let newAparelhos = []

        lines.forEach(line => {
            // Procura se aparelho já existe no array newAparelhos
            // se existe idx !== -1
            let idx = newAparelhos.findIndex(setor => setor[0] === line.aparelho)

            // Se existe, adiciona potencia e annotation ao final
            // ex: newAparelhos = [['Geladeira', 1000, 'LG']]
            if (idx !== -1) {
                //newAparelhos = [['Geladeira', 1000, 'LG', 400]]
                newAparelhos[idx].push(parseFloat(powerCalculation(line)))
                    //newAparelhos = [['Geladeira', 1000, 'LG', 400, 'Brastem']]
                newAparelhos[idx].push(renderAnnotation(line))
            }

            // Caso contrário, cria um novo array contendo o aparelho a primeira potência
            // ex: newAparelhos = [['Geladeira', 1000, 'LG']]
            else {
                /* newAparelhos = [
                  ['Geladeira', 1000, 'LG'],
                  ['Televisão', 1170, 'Sanmsung']]
                */
                newAparelhos.push([line.aparelho, parseFloat(powerCalculation(line)), renderAnnotation(line)])
            }
        })

        // Acha a quantidade máxima de modelos dentro de um aparelho
        let temp = []
        newAparelhos.forEach(aparelho => {
            temp.push(aparelho.length)
        })

        let max = Math.max(...temp)
            // Retira o primeiro item, que é o nome do aparelho, e divide por 2 para tirar os {role: 'annotation'}
        let maxWithoutSetor = (max - 1) / 2

        // Adiciona potências 0 para padronizar a quantidade de aparelhos em cada setor
        newAparelhos.forEach((setor, idx) => {
            for (let i = setor.length; i < max; i += 2) {
                newAparelhos[idx].push(null)
                newAparelhos[idx].push('')
            }
        })

        // Cria o header no formato
        // header = ['Setores', 'Potência', {'type': 'string', 'role': 'tooltip', 'p': {'html': true}}, 'Potência', {'type': 'string', 'role': 'tooltip', 'p': {'html': true}}, 'Potência', {'type': 'string', 'role': 'tooltip', 'p': {'html': true}}]
        let header = ['Setores']
        for (let i = 0; i < maxWithoutSetor; i++) {
            header.push('Potência')
                // Padrão da biblioteca
            header.push({ 'type': 'string', 'role': 'tooltip', 'p': { 'html': true } })
        }
        // Junta o header com os setores
        let newData = [header, ...newAparelhos]

        // Atualiza o estado
        setData(newData)
    }

    useEffect(() => {
        updateData()
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calcularClicked])

    return (
		<Chart
			width = { '100%' }
            height = { '500px' }
            chartType = 'BarChart'
            loader = { <div className = "loader"> </div>}
			data = { data }
			options = {
				{
					fontSize: 16,
					hAxis: {
						title: 'Consumo (em kWh)',
						titleTextStyle: {
							fontSize: 18,
						},
						minValue: 0,
					},
					tooltip: { isHtml: true },
					seriesType: 'bars',

					legend: { position: 'none' },
					isStacked: true,
					colors: ["1b2d51", "162867", "11227c", "0a1a9c", "0d20a7", "0f26b1", "1635bb", "1e44c5", "224bca", "2552cf", "224bca", "1e44c5", "1635bb", "0f26b1", "0d20a7", "0a1a9c", "11227c", "162867"],
					chartArea: {
						left: 100,
						top: 30,
						bottom: 80,
						right: 30,
					}
				}
			}
		/>
	);
}