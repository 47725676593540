import React from 'react';
import { string, func } from 'prop-types';

import './Button.css';

export default function Button({ text, onClick }) {

  return (
    <div className='button' onClick={onClick} >
      {text}
    </div>
  );
}

Button.propTypes = {
  text: string,
  onClick: func
}