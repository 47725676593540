import React, { useState } from 'react';
import { shape, func, string } from 'prop-types';

import Input from './Input';
import Select from './Select';


import Aparelhos from './aparelhos.json';
import { isTypeA, isTypeB } from '../utils/powerCalculation';

export default function Inputs({ line, onChange }) {
  const [modelosInSelect, setModelosInSelect] = useState([])

  const handleAparelhoChange = e => {
    onChange(e)
    line.modelo = ''
    line.potencia = ''
    line.tempo = ''
    line.setor = Aparelhos[line.aparelho].setor

    if (isTypeA(line)) {
      line.tempo = '24'
    }
    setModelosInSelect(Aparelhos[line.aparelho].modelos)
  }

  const handleModeloChange = e => {
    onChange(e)
    /*
      modelosInSelect = [
        { "modelo": "LG", "potencia": "1500" },
        { "modelo": "Sansung", "potencia": "3000" },
        { "modelo": "Brasten", "potencia": "2500" },
        ...
      ]
    */
    // Acha o modelo certo
    const modeloObj = modelosInSelect.find(item => item.modelo === line.modelo)
    // modelosObj = { "modelo": "Sansung", "potencia": "3000" }
    line.potencia = modeloObj.potencia;
  }

  console.log(line);

  return (
    <div className='inputs'>
      <Select
        name='aparelho'
        value={line.aparelho}
        data={Object.keys(Aparelhos)}
        onChange={handleAparelhoChange}
      />
      <Select
        name='modelo'
        value={line.modelo}
        data={modelosInSelect}
        onChange={handleModeloChange}
      />
      <Input
        name='potencia'
        value={isTypeA(line) || isTypeB(line) ? '---' : line.potencia}
        type='text'
        onChange={onChange}
        disabled
      />
      <Input
        name='quantidade'
        value={line.quantidade}
        type='number'
        onChange={onChange}
      />
      <Input
        name='tempo'
        value={line.tempo}
        type='number'
        min="1"
        max="24"
        onChange={onChange}
        disabled={isTypeA(line)}
      />
    </div>
  );
}

Inputs.propTypes = {
  line: shape({
    aparelho: string,
    modelo: string,
    potencia: string,
    quantidade: string,
    tempo: string
  }),
  onChange: func
}