import React from 'react';
import Header from './Header';
import Inputs from './Inputs';
import Button from './Button';

import './Table.css';

export const inputs = {
  aparelho: '',
  setor: '',
  modelo: '',
  potencia: '',
  quantidade: '',
  tempo: ''
};

export default function Table({ setCalcularClicked, lines, setLines }) {

  const handleInputChange = idx => e => {
    const { name, value } = e.target

    // Copia o array lines
    let allLines = [...lines]

    // Muda o key name (ex. name = 'aparelho') da linha idx para o valor 'value' (ex. value = 'Geladeira')
    allLines[idx][name] = value;
    // Mesma coisa que changedLine.name = value
    if (name === 'quantidade' && value < 0) {
      allLines[idx][name] = 0;
    }
    
    if (name === 'tempo') {
      if (value > 24) allLines[idx][name] = 24;
      if (value < 0) allLines[idx][name] = 0;
    }

    // Atualiza lines
    setLines(allLines)
  }

  const handleRemoveLine = idx => e => {
    // Remove a linha idx
    lines.splice(idx, 1)
    // Atualiza lines
    setLines([...lines])
  }

  const handleAddLine = () => {
    // Adiciona o objeto inputs no final do array lines
    setLines([...lines, { ...inputs }])
    /*
      // Copia o array lines
      let linesCopy = [...lines]
 
      // Copia o objeto inputs
      let inputsCopy = {...inputs}
 
      linesCopy.push(inputsCopy)
      setLines(linesCopy)
    */
  }

  const handleInputEmpty = () => {
    var isEmpty = false
    // Recebe HTMLCollection com todas as tags com className input
    let inputs = document.getElementsByClassName('input')

    // Itera cada um dos inputs
    Array.from(inputs).forEach(input => {

      // Se for vazio, troca a cor para vermelho
      if (input.value === '') {
        input.style.border = '4px solid red'
        isEmpty = true
      }

      // Se não for vazio, troca de cor para laranja
      else {
        input.style.border = '4px solid lightgrey'
      }
    });
    return isEmpty
  }

  const handleCalcular = () => {
    if (!handleInputEmpty()) {
      setCalcularClicked(prevState => prevState + 1)
    }
  }

  function renderLine(line, idx) {
    return (
      <div key={idx} className='line'>
        <Inputs
          line={line}
          onChange={handleInputChange(idx)}
        />
        {idx !== 0 &&
          <Button
            text='⨯'
            onClick={handleRemoveLine(idx)}
          />
        }
      </div>
    );
  }

  return (
    <div className='grid'>
      <Header />

      <section className='main'>
        {lines.map(renderLine)}
      </section>

      <section className='footer'>
        <Button text='+' onClick={handleAddLine} />

        <div className='calcular-button' onClick={handleCalcular}>
          Calcular
        </div>
      </section>
    </div>
  );
}