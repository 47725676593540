import React from 'react';
import { string, func } from 'prop-types';

import './Input.css';

export default function Input({ name, value, type, onChange, ...props }) {
  const placeholder = (
    name === 'tempo' ? 'Tempo (h/dia)' :
      name === 'potencia' ? 'Potência (W)' :
        name === 'quantidade' ? 'Unidade' : ''
  );

  return (
    <input
      name={name}
      value={value}
      type={type}
      min='0'
      placeholder={placeholder}
      className='input'
      onChange={onChange}
      {...props}
    />
  );
}

Input.propTypes = {
  name: string,
  value: string,
  type: string,
  onChange: func,
}