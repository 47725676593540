import React from 'react';

import './Title.css';

export default function Title() {
    return (
        <div className='hero-image'>
            <div className='hero-text'>
                <p className='title'>CALCULADORA ENERGÉTICA</p>
            </div>
        </div>
    );
}