import React from 'react';
import BarChart from './BarChart';
import PieChart from './PieChart';

import './Results.css';


export default function Results({ lines, calcularClicked }) {

  return (
    <div className='results-container'>
      <div className='charts-container1'>
        <div>
          <div className='result-column-title'>CONSUMO POR EQUIPAMENTO</div>
          <BarChart lines={lines} calcularClicked={calcularClicked}/>
        </div>
      </div>

      <div className='charts-container2'>
        <div>
          <div className='result-column-title'>EQUIPAMENTOS SETORIZADOS</div>
          <PieChart lines={lines} calcularClicked={calcularClicked}/>
        </div>
      </div>

      <div className='text-container'>
        Quer saber como economizar energia na sua residência
        ou no seu negócio?&nbsp;
        <a href="https://renovajr.com.br/contato/" target="_blank" rel="noopener noreferrer">Entre em contato conosco!</a>
      </div>
    </div>
  );
}