const typeA = ["Geladeira", "Frigobar"];
const typeB = ['Máquina semi-automática', 'Máquina automática', 'Máquina lava & seca'];
const mes = 30;

function powerCalculation(device) {
    if (typeA.includes(device.aparelho)) {
        return (device.quantidade * device.potencia) / 1000;
    } else {
        return (device.quantidade * device.potencia * device.tempo * mes) / 1000;
    }
}

export function isTypeA(device) {
    if (typeA.includes(device.aparelho)) {
        return true;
    }
    return false;
}

export function isTypeB(device) {
    if (typeB.includes(device.aparelho)) {
        return true;
    }
    return false;
}

export default powerCalculation;